import React from "react";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import EmailIcon from "@mui/icons-material/Email";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

const Contact = () => {
  return (
    <>
      <div id="contact">
        <div className="mt-4 pt-20 flex items-center space-x-2 text-black flex-grow justify-center font-bold text-2xl font-roboto-mono">
          Contact Me
        </div>
        <div className="flex items-center space-x-2 text-black text-center flex-grow justify-center font-roboto-mono text-m pt-2 pb-6">
          Book for a complementary call today
        </div>
        <div className="text-black flex-grow justify-center font-roboto-mono">
          <div>
            <div className="flex justify-center pb-1">
              <PermPhoneMsgIcon /> Phone
            </div>
            <div className="flex justify-center pb-2 hover:text-[#415a77]">+447951361903</div>
          </div>
          <div>
            <div className="flex justify-center pb-1">
              <EmailIcon /> Email
            </div>
            <div className="flex justify-center pb-2 hover:text-[#415a77]">chiomawow[at]gmail.com</div>
          </div>
          <div>
            <div className="flex justify-center pb-1">
              <PodcastsIcon />
              Podcast
            </div>
            <a href="https://open.spotify.com/episode/6IjK8695sUDVkBRNonvwiF" className="hover:text-[#415a77] pb-2 flex justify-center">
              spotify.com/episode/...
            </a>
          </div>
          <div>
            <div className="flex justify-center pb-1">
              <TwitterIcon />
              Twitter
            </div>
            <a href="https://twitter.com/Chiomawow" className="hover:text-[#415a77] flex justify-center pb-2">twitter.com/Chiomawow</a>
          </div>
          <div>
            <div className="flex justify-center pb-1">
              <LinkedInIcon />
              LinkedIn
            </div>
            <a href="https://www.linkedin.com/in/chioma-fanawopo-6716689/" className="hover:text-[#415a77] flex justify-center pb-2">
              linkedin.com/in/chioma-fanawopo-6716689/
            </a>
          </div>
          <div>
            <div className="flex justify-center pb-1">
              <InsertLinkIcon />
              Linktree
            </div>
            <a href="https://linktr.ee/ChiomaFanawopo" className="hover:text-[#415a77] flex justify-center pb-2">
              linktr.ee/ChiomaFanawopo
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
