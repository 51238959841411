import "./index.css";
import Header from "./components/Header";
import Carousel from "./components/Carousel";
import About from "./components/About";
import Contact from "./components/Contact";
import Packages from "./components/Packages";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Header />
      <Carousel />
      <About />
      <Testimonials />
      <Packages />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
