import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

// Import your images
import img1 from "../assets/img-1.png";
import img2 from "../assets/img-2.png";
import img3 from "../assets/img-3.png";
import img4 from "../assets/img-4.png";

const ImageCarousel = () => {
  // Array of items to display in the carousel
  const items = [
    {
      img: img1,
      alt: "Image 1",
    },
    {
      img: img2,
      alt: "Image 2",
    },
    {
      img: img3,
      alt: "Image 3",
    },
    {
      img: img4,
      alt: "Image 4",
    },
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

const Item = (props) => {
  return (
    <Paper
      elevation={2}
      style={{
        position: "relative",
        height: 600, // Set the desired height for the carousel container
        width: "100%", // Set the width to take up the full container width
        overflow: "hidden",
        display: "flex",
        flexDirection: "row", // Set direction to row
        justifyContent: "flex-start", // Align content to start
        alignItems: "center", // Center the image vertically
        backgroundColor: "#415a77", // Default background color for Paper
      }}
    >
      <img
        src={props.item.img}
        alt={props.item.alt}
        style={{
          height: 600, // Fixed height for the image
          width: 750, // Fixed width for the image
          objectFit: "cover",
        }}
      />
      <div
        style={{
          flex: 1, // This div will take up all remaining space
          backgroundColor: "#415a77", // Set the background for the div
        }}
      />
    </Paper>
  );
};

export default ImageCarousel;
