import React, { useState } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";

const Testimonials = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState("test1");

  const radioStyles = {
    color: "#415a77", // your custom color for the unchecked state
    "&.Mui-checked": {
      color: "#415a77", // your custom color for the checked state
    },
  };

  const testimonialsData = {
    test1: {
      name: "Taiwo Ajisefinni Assoc CIPD",
      content:
        "Chioma delivered a training session on diversity, equality, and inclusion to a group of Trustees for Mayah's Legacy, which I attended. The training session was informative, educational, and thought-provoking. The session was positively delivered and critical points raised were dealt with sensitively. I would highly recommend Chioma.",
    },
    test2: {
      name: "Keji Moses (FCMI)",
      content:
        "Chioma delivered equality, diversity and inclusion training focused on intersectionality for Mayah's Legacy trustees. The session was stimulating and educational. She gave a clear overview that affirms the work and values of Mayah's legacy. Her presentation was easy to understand and digest. I strongly recommend her.",
    },
    test3: {
      name: "Terry Millar",
      content:
        "Chioma delivered an incredibly powerful and thought-provoking session as keynote speaker on unconscious bias as part of our Trust Day 2022 to all the staff, governors, trustees and members of our Trust. Feedback from all those present was overwhelmingly positive, and highly appreciative of the approach she took. This is just the start, and we look forward to welcoming Chioma back in September to deliver further sessions in our schools. If you are looking for someone to engage with your teams in a real way, I would highly recommend speaking with Chioma. ",
    },
    test4: {
      name: "Dr Grace Bally-Balogun DMin MA MSc FRSA",
      content:
        "As one of the Facilitators, Chioma brilliantly hosted some of our sessions at the annual 2020 The Deborah Gathering conference. She demonstrated excellent facilitation skills, keeping the audience thoroughly engaged and managing the sessions so well. Her bright and warm personality ensured that the sessions were energised. Also, as a member of the Conference Planning Team, she was a great asset bringing creative ideas and supporting the administrative side. She comes with my highest recommendation!",
    },
    test5: {
      name: "Anna Somuyiwa (Adedeji)",
      content:
        "I invited Chioma to speak at my Bounce Back online masterclass series, the topic was 'Wellbeing'. I received excellent feedback on her presentation and overall leadership of discussions during the Zoom call. A delegate said 'it felt like having a chat with a close friend'. She talked about her life and experience, with practical non-judgemental advice that left everyone with tips for future actions, also knowing there isn't a competition to be won, just life to be lead and managed in such a way that we are well, body soul and spirit. I highly recommend her and will use her for future online and in person engagements!",
    },
  };

  return (
    <>
      <div id="testimonials">
        <header className="mt-12 pt-20 flex items-center space-x-2 text-black flex-grow justify-center font-bold text-2xl font-roboto-mono">
          Testimonials
        </header>
        <p className="flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono text-m pt-2 pb-6">
          Some client testimonials
        </p>

        <div className="mt-10 pt-5 text-center">
          <h3 className="font-bold text-xl font-roboto-mono text-center">
            {testimonialsData[selectedTestimonial].name}
          </h3>
          <p className="font-roboto-mono mt-4 px-6 sm:px-20 md:px-32 lg:px-40 text-center">
            {testimonialsData[selectedTestimonial].content}
          </p>
        </div>

        <div className="mt-10 pt-6 flex justify-center text-center">
          <RadioGroup
            row
            value={selectedTestimonial}
            onChange={(e) => setSelectedTestimonial(e.target.value)}
          >
            <FormControlLabel
              value="test1"
              control={<Radio sx={radioStyles} />}
              labelPlacement="top"
            />
            <FormControlLabel
              value="test2"
              control={<Radio sx={radioStyles} />}
              labelPlacement="top"
            />
            <FormControlLabel
              value="test3"
              control={<Radio sx={radioStyles} />}
              labelPlacement="top"
            />
            <FormControlLabel
              value="test4"
              control={<Radio sx={radioStyles} />}
              labelPlacement="top"
            />
            <FormControlLabel
              value="test5"
              control={<Radio sx={radioStyles} />}
              labelPlacement="top"
            />
          </RadioGroup>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
