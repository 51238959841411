import { Link } from "react-scroll";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

const Footer = () => {
  return (
    <>
      <div className="pt-5 lg:pt-20">
        <footer className="bg-[#415a77] text-white font-roboto-mono">
          <div className="flex flex-col md:flex-row justify-between items-center py-5 px-5 md:py-10 md:px-10 lg:py-20 lg:px-20 mx-auto space-y-5 md:space-y-0 md:space-x-5 lg:space-x-10">
            {/* Left side - Name and title */}
            <div className="space-y-1 text-center md:text-left">
              <div className="text-xl md:text-2xl font-bold">Chioma</div>
              <div className="text-xl md:text-2xl font-bold">Fanawopo</div>
              <div>Diversity and Inclusion Specialist</div>
              <div>Trainer / Facilitator / Speaker</div>
            </div>
            {/* Center - Navigation links */}
            <ul className="flex flex-wrap justify-center md:justify-start space-x-2 md:space-x-4 text-sm md:text-base">
              <li>
                <Link
                  to="about-me"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="hover:text-gray-300"
                >
                  About Me
                </Link>
              </li>
              <li>
                <Link
                  to="testimonials"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="hover:text-gray-300"
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  to="packages"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className="hover:text-gray-300"
                >
                  Packages
                </Link>
              </li>
            </ul>

            {/* Right side - Icons */}
            <div className="flex justify-center md:justify-start space-x-4">
              <a
                href="https://www.linkedin.com/in/chioma-fanawopo-6716689/"
                className="hover:text-gray-300"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://open.spotify.com/episode/6IjK8695sUDVkBRNonvwiF"
                className="hover:text-gray-300"
              >
                <PodcastsIcon />
              </a>
              <a
                href="https://twitter.com/Chiomawow"
                className="hover:text-gray-300"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://linktr.ee/ChiomaFanawopo"
                className="hover:text-gray-300"
              >
                <InsertLinkIcon />
              </a>
            </div>
          </div>
          {/* Bottom Center - Copyright Notice */}
          <div className="text-center py-4 text-s pb-12">
            @ChiomaFanawopo All rights reserved
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
