import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "../index.css";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);

  // Event handler to be called on scroll
  const handleScroll = () => {
    // Set the sticky state based on scroll position
    setIsSticky(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="home">
    <nav
      className={`bg-white flex items-center justify-between flex-wrap pt-4 pb-6 px-4 md:space-x-2 lg:space-x-4 ${isSticky ? 'fixed top-0 left-0 right-0 z-50' : 'sticky'}`}
    >
      <div className="hover:text-[#415a77] text-xl font-medium flex-none uppercase font-roboto-mono mb-2 md:mb-0">
        <div>Chioma</div>
        <div>Fanawopo</div>
      </div>
      {/* Wrap your navigation items with Link from react-scroll */}
      <Link
        to="home"
        spy={true}
        smooth={true}
        duration={500}
        className="hover:text-[#415a77] flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono cursor-pointer"
      >
        <HomeIcon />
        <span>Home</span>
      </Link>
      <Link
        to="about-me"
        spy={true}
        smooth={true}
        duration={500}
        className="hover:text-[#415a77] flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono cursor-pointer"
      >
        <PersonIcon />
        <span>About Me</span>
      </Link>
      <Link
        to="testimonials"
        spy={true}
        smooth={true}
        duration={500}
        className="hover:text-[#415a77] flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono cursor-pointer"
      >
        <PeopleIcon />
        <span>Testimonials</span>
      </Link>
      <Link
        to="packages"
        spy={true}
        smooth={true}
        duration={500}
        className="hover:text-[#415a77] flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono cursor-pointer"
      >
        <BusinessCenterIcon />
        <span>Packages</span>
      </Link>
      <Link
        to="contact"
        spy={true}
        smooth={true}
        duration={500}
        className="hover:text-[#415a77] flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono cursor-pointer"
      >
        <LocalPhoneIcon />
        <span>Contact Me</span>
      </Link>
    </nav>
    </div>
  );
};

export default Navbar;
