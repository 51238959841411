import React from "react";

const Packages = () => {
  return (
    <>
      <div id="packages">
        <div className="mt-12 pt-20 flex items-center space-x-2 text-black flex-grow justify-center font-bold text-2xl font-roboto-mono">
          Packages
        </div>
        <div className="flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono text-m pt-2 pb-6">
          The packages I currently offer
        </div>
        <div className="px-20 pt-10">
          <div className="px-4">
            <div className="font-roboto-mono text-xl flex-grow text-black flex font-bold justify-center pt-2 text-center">
              Book me for
            </div>
            <div className="font-roboto-mono text-xl flex-grow text-black flex font-bold justify-center text-center">
              speaking engagement
            </div>
            <div className="font-roboto-mono text-m flex-grow text-black flex pt-6 pb-10 mt-5 mb-8 text-center">
              I am an award winning, Forbes Featured teen and parent coach. Also
              a mother of 2 boys: 18 and 11 years. As a practitioner, I have
              over 18 years experience in supporting young people and their
              families. In addition, I am an Educator, Well-being Practitioner
              with an emphasis on promoting positive mental health for the whole
              family. I am also a mentor to young adults, a speaker, coach, a
              former school governor. In addition, I am a youth mental health
              first aider and a JNC qualified youth minister. Book me for any
              speaking engagements on raising teenagers or parenting.
            </div>
          </div>
          <div className="px-6">
            <div className="font-roboto-mono text-xl flex-grow text-black flex font-bold justify-center text-center">
              Facilitation
            </div>
            <div className="font-roboto-mono text-m text-black pt-6 pb-10 mt-5 text-center px-4 sm:px-6 md:px-8 lg:px-10">
              I am an experienced facilitator and create interactive, inclusive
              and engaging sessions for teams. I can deliver bespoke and
              interactive sessions or workshops or tailored to your group on
              Equality and diversity, contextual safeguarding, county lines,
              social media and its impact on adolescents, parenting styles,
              parental engagement, wellbeing for parents and teenagers, mental
              health, transition from primary to secondary school, Trauma/ACEs,
              and online safety. Send me a message to book my services.
            </div>
          </div>
          <div>
            <div className="font-roboto-mono text-xl flex-grow text-black flex font-bold justify-center text-center">
              Teen/parent coaching
            </div>
            <div className="font-roboto-mono text-m text-black pt-6 pb-10 mt-5 text-center px-4 sm:px-6 md:px-8 lg:px-10">
              As a seasoned speaker with a wealth of experience, I have had the
              privilege of working closely with a diverse range of
              organizations. My passion for communication and inspiring others
              has led me to speak at both small and medium-sized organizations,
              bringing my expertise to audiences craving impactful and
              insightful messages. I bring a unique perspective to the table,
              having collaborated with various industries and sectors, including
              technology, education, healthcare, and finance. Through my
              speaking engagements, I aim to empower individuals and teams to
              overcome challenges, foster collaboration, and embrace personal
              growth. With a genuine enthusiasm for connecting with audiences, I
              prioritize creating an engaging and interactive environment during
              my presentations. Whether it's delivering a keynote talk,
              facilitating workshops, or participating in panel discussions, my
              focus remains on providing valuable insights and practical
              takeaways that can be immediately applied to real-world scenarios.
              My speaking style is known for its authenticity, warmth, and
              relatability. I believe in the power of storytelling and
              incorporating real-life examples, humor, and relatable anecdotes
              to captivate audiences and foster a sense of connection. By
              personalizing my talks, I create an atmosphere of trust and open
              dialogue, ensuring that every attendee feels seen, heard, and
              motivated to make positive changes in their professional and
              personal lives. Please feel free to reach out to discuss further
              details or to inquire about my availability.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
