import "../index.css";

const About = () => {
  return (
    <>
      <div>
        <header id="about-me" className="mt-6 pt-12 flex items-center space-x-2 text-black flex-grow justify-center font-bold text-2xl font-roboto-mono">
          About Me
        </header>

        <p className="flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono text-m pt-2 pb-12">
          My Introduction
        </p>

        <p className="px-20 flex items-center space-x-2 text-black flex-grow justify-center font-roboto-mono text-center mt-8">
          My name is Chioma Fanawopo and I am Forbes
          Featured and an Award winning parent and teen coach. With over 18
          years of experience in education, private and third sector in
          diversity and Inclusion, I have become an expert in delivering
          facilitation services that promote diversity, equity, and inclusion in
          a variety of settings. Throughout my career, I have worked with a wide
          range of organizations, including schools, non-profits, and private
          companies as an educator, school governor, project lead, trustee and
          consultant. One of my strengths as a specialist in this area is my
          ability to build strong relationships with schools and other
          stakeholders including parents. I am skilled at listening to their
          needs and concerns, and I take a collaborative, client-centred
          approach to my work. This allows me to create tailored solutions that
          are both effective and sustainable. Over the years, I have developed a
          deep understanding of the complex issues surrounding diversity,
          equity, and inclusion. I am confident in my ability to facilitate
          difficult conversations around these topics, and I always strive to
          create a safe, interactive and inclusive environment for all
          participants. I can speak on the 9 protected characteristics of the
          Equality Law including Intersectionality, Adultification Bias,
          Neurodiversity and Gender. In addition, I run assemblies, workshops,
          Black History lessons for school children on food/culture, Identity,
          Representation and prominent Black Tudors and professionals. My
          facilitation style is engaging, educational and empowering. In
          addition to my facilitation work, I have also developed a strong
          reputation as a thought leader in this field. I have published
          numerous articles on LinkedIn on the subject of diversity, equity, and
          inclusion, and I frequently speak at conferences and other events.
          Overall, I am passionate about helping organisations create more
          diverse, equitable, and inclusive workplaces and communities. With my
          extensive experience, deep understanding of these issues, and
          Interactive/collaborative approach, I believe I can be a valuable
          asset to you in promoting belonging, diversity, equity, and inclusion.
          I have a valid DBS and I am a mental health first aider.
        </p>
      </div>
    </>
  );
};

export default About;
